module.exports = {
  "messages": {
    "A11Y_LOGO": "Go to XING homepage",
    "A11Y_SETTINGS_DROPDOWN_LABEL": "Edit settings",
    "ACTION_BLOCK_USER": "Block this user",
    "ACTION_BLOCK_USER_DIALOG_DESCRIPTION": "Blocking {displayName} means they can't view your profile or contact you via XING.",
    "ACTION_BLOCK_USER_DIALOG_TITLE": "Really block this user?",
    "ACTION_BLOCK_USER_SUCCESS": "Member blocked",
    "ACTIVE_LANGUAGE": "English",
    "ARMSTRONG_DISCO_NAVIGATION_ICON_TEXT": "Exchange",
    "BREWERY_NOCOOKIES_LINK_TARGET": "https://faq.xing.com/en/security/what-are-cookies-and-why-do-i-need-them-log",
    "BREWERY_NOCOOKIES_LINK_TEXT": "Activate cookies",
    "BREWERY_NOCOOKIES_PARAGRAPH": "You need to activate cookies to log in. Here's how to do that:",
    "BREWERY_NOCOOKIES_TITLE": "Please activate cookies.",
    "BREWERY_NOJAVASCRIPT_LINK_TARGET": "https://faq.xing.com/en/settings/enable-javascript-my-browser",
    "BREWERY_NOJAVASCRIPT_LINK_TEXT": "Activate JavaScript",
    "BREWERY_NOJAVASCRIPT_PARAGRAPH": "XING only works properly with JavaScript. Here's how to activate it:",
    "BREWERY_NOJAVASCRIPT_TITLE": "Please activate JavaScript.",
    "BUTTON_BACK": "Back",
    "BUTTON_CANCEL": "Cancel",
    "BUTTON_CLOSE": "Close",
    "BUTTON_CLOSEWINDOW": "Close window",
    "BUTTON_CONTINUE": "OK",
    "BUTTON_FORWARD": "Next",
    "CAL_DATE_FORMAT": "DD/MM/YYYY",
    "CAL_DATE_FORMAT_HINT": "DD/MM/YYYY",
    "CB_POSTING_ERROR": "Sorry, that didn't work. Please try again.",
    "COMMBOX_ACTOR_SWITCH_CANCEL": "Keep role",
    "COMMBOX_ACTOR_SWITCH_CONFIRM": "Change role",
    "COMMBOX_ACTOR_SWITCH_HEADLINE": "In what role do you want to post this?",
    "COMMBOX_ACTOR_SWITCH_HINT": "To change the author of this post, just tap on the name above.",
    "COMMBOX_ACTOR_SWITCH_PROMPT_IMAGE": "Do you really want to change the name of the author? You'd then have to re-upload the image.",
    "COMMBOX_ACTOR_SWITCH_PROMPT_VIDEO": "Do you really want to change the name of the author? You'd then have to re-upload the video.",
    "COMMBOX_ACTOR_SWITCH_TYPE_PAGE": "Page",
    "COMMBOX_ACTOR_SWITCH_TYPE_USER": "Member",
    "COMMBOX_ALMOST_FULL_FILLED_DROPZONE_HEADER": "You can add 1 more image to your post.",
    "COMMBOX_AUDIENCE_CITY_CONTACTS": "Only contacts in my city ({totalCount})",
    "COMMBOX_AUDIENCE_FOLLOWERS": "Followers only",
    "COMMBOX_AUDIENCE_FOLLOWERS_AND_CONTACTS": "Followers & contacts",
    "COMMBOX_AUDIENCE_FOLLOWERS_AND_CONTACTS_DESC": "Your post can be shared",
    "COMMBOX_AUDIENCE_FOLLOWERS_DESC": "Your post can be shared.",
    "COMMBOX_AUDIENCE_HEADLINE": "Who can see this?",
    "COMMBOX_AUDIENCE_PRIVATE": "Private (XING contacts only)",
    "COMMBOX_AUDIENCE_PRIVATE_DESC": "Your post can't be shared.",
    "COMMBOX_AUDIENCE_PRIVATE_SELECTED": "Private",
    "COMMBOX_AUDIENCE_PUBLIC": "Public",
    "COMMBOX_AUDIENCE_PUBLIC_DESC": "Anyone can see and share this.",
    "COMMBOX_BUTTON_ADD_VIDEO_LABEL": "Select video",
    "COMMBOX_BUTTON_OPEN_FILES_LABEL": "Select document",
    "COMMBOX_BUTTON_OPEN_POLL_LABEL": "Create a poll",
    "COMMBOX_CANCEL_POSTING_BUTTON_BACK": "Continue editing",
    "COMMBOX_CANCEL_POSTING_BUTTON_CANCEL": "Discard post",
    "COMMBOX_CANCEL_POSTING_HEADLINE": "Discard post?",
    "COMMBOX_CANCEL_POSTING_TEXT_MESSAGE": "You'll lose what you wrote if you discard it.",
    "COMMBOX_CHARACTERS_LEFT": "left",
    "COMMBOX_EMPTY_DROPZONE_BUTTON_TEXT": "Select images",
    "COMMBOX_EMPTY_DROPZONE_TEXT": "JPG, BMG or PNG (max. {max_size} MB each)",
    "COMMBOX_EMPTY_DROPZONE_TITLE": "Drag & drop or select up to {max_images} images",
    "COMMBOX_EMPTY_TEXT_FIELD": "Share your thoughts, a photo or a link...",
    "COMMBOX_EMPTY_TEXT_FIELD_MENTIONS": "Create a new post (and use '@' to mention others)…",
    "COMMBOX_ENTRY_POINT_LABEL": "Text box for your post",
    "COMMBOX_ENTRY_POINT_TEXT": "What's new with you?",
    "COMMBOX_FILLED_DROPZONE_HEADER": "You can add {pictures} more images to your post.",
    "COMMBOX_FULL_FILLED_DROPZONE_HEADER": "You've reached the maximum number of photos you can post.",
    "COMMBOX_IMAGE_BUTTON_LABEL": "Add image",
    "COMMBOX_IMAGE_SIZE_UPLOAD_ERROR": "Please select an image less than 10 MB in size.",
    "COMMBOX_IMAGE_TYPE_UPLOAD_ERROR": "Please select an image in JPG or PNG format.",
    "COMMBOX_POST": "Post",
    "COMMBOX_SUCCESS_ACTION": "View post",
    "COMMBOX_SUCCESS_DESC": "Your post is now live on XING.\t",
    "COMMBOX_SUCCESS_HEADLINE": "Great!",
    "COMMBOX_SUCCESS_SCHEDULED_DESC": "Your message was scheduled for {time} on {date}.",
    "COMMBOX_UPDATE_BTN": "Update",
    "COMMBOX_UPLOADED_IMAGES_LIMIT_REACHED": "You've reached the limit of 10 images.",
    "COMMBOX_VIDEO_UPLOAD_ENCODING_TITLE": "Your video is being prepared. This will take a moment.",
    "COMMBOX_VIDEO_UPLOAD_GENERIC_ERROR": "Sorry, that didn't work. Please try again.",
    "COMMBOX_VIEW_POST_BTN": "View post",
    "COMMENT_BUTTON_LABEL": "Comment",
    "CONTENT_ERROR_404_BUTTON": "Go to XING homepage",
    "CONTENT_ERROR_404_HEADLINE": "This page appears to have gone with the wind.",
    "CONTENT_ERROR_404_SUBLINE": "But go ahead and check out what else is on offer:",
    "CONTENT_ERROR_503_HEADLINE": "Looks like the server is playing cat and mouse with us.",
    "CONTENT_ERROR_503_SUBLINE": "Please bear with us while we get some cheese for them.",
    "CONTENT_ERROR_OFFLINE": "Please check your Internet connection and try again.",
    "CONTENT_FOLLOW": "Follow",
    "CONTENT_FOLLOWED": "Following",
    "CONTENT_SWITCHER_MORE": "More",
    "CONTENT_TIMESTAMP_AN_HOUR_AGO_LONG": "{minutesCount, plural,\n   =1 {Just now}\n   other {# minutes ago}\n}",
    "CONTENT_TIMESTAMP_AN_HOUR_AGO_SHORT": "{minutesCount, plural,\n   =1 {Now}\n   other {# mins}\n}",
    "CONTENT_TIMESTAMP_DAYS": "days",
    "CONTENT_TIMESTAMP_DAYS_AGO_LONG": "{daysCount, plural,\n    =0 {Today}\n    one {Yesterday}\n    other {# days ago}\n}",
    "CONTENT_TIMESTAMP_DAYS_AGO_SHORT": "{daysCount, plural,\n    =0 {Today}\n    one {Yesterday}\n    other {# days}\n}",
    "CONTENT_TIMESTAMP_LESS_THAN_A_MINUTE_AGO": "Just now",
    "CONTENT_TIMESTAMP_SOME_HOURS_AGO_LONG": "{hoursCount, plural,\n   =1 {1 hour ago}\n   other {# hours ago}\n}",
    "CONTENT_TIMESTAMP_SOME_HOUR_AGO_SHORT": "{hoursCount, plural,\n   =1 {1 hr}\n   other {# hrs}\n}",
    "CONTENT_TIMESTAMP_TODAY": "Today",
    "CONTENT_UNFOLLOW": "Do not follow",
    "CONTENT_VIDEO_ARTICLE_HEADLINE_PLACEHOLDER": "Add a title for your video here.",
    "CONTENT_VIDEO_RECOS_REASON": "Popular videos on XING",
    "CROSSPOST_SHARE_ERROR": "Sorry, but you already shared this post in 3 other groups.",
    "DAY_NAMES": "Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday",
    "DAY_NAMES_SHORT": "Mo,Tu,We,Th,Fr,Sa,Su",
    "DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_CONFIRM": "Turn off",
    "DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_HEADLINE": "Turn off mentions?",
    "DISCO_DOT_MENU_BLOCK_MENTIONS_DIALOG_TEXT": "Your name won't link to your XING profile.",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_COMPANIES": "A company",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_EVENTS": "An event",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_GROUPS": "A group",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_JOBS": "A job",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_MEMBERS": "A person",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_NEWS": "An article",
    "DS_PREDICTIVE_SEARCH_NAVIGATION_SUGGESTIONS_TOPICS": "A topic",
    "DS_PREDICTIVE_SEARCH_QUERY_SUGGESTIONS_JOBS": "Discover the many {keyword} jobs on offer",
    "DS_PREDICTIVE_SEARCH_QUERY_SUGGESTIONS_JOBS_SHORT": "Discover {keyword} jobs",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_INCREMENTAL_SEARCH": "Search suggestions",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_NAVIGATION_SUGGESTIONS": "What are you looking for?",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_QUERY_SUGGESTION": "Search suggestions",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECENT_INTERACTIONS": "Profiles you recently visited",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECENT_SEARCHES": "Your recent job searches",
    "DS_PREDICTIVE_SEARCH_SECTION_TITLE_RECOMMENDATION": "Recommendations",
    "DUPLICATE_SHARE_ERROR": "You already shared this so your contacts are in the loop.",
    "ERROR_SOMETHING_WENT_WRONG": "Sorry, something went wrong. Please try again later.",
    "FOOTER_LANGUAGES": "Language",
    "FRAME_HEADER_UPSELL_PREMIUM": "Go Premium",
    "FRAME_HEADER_UPSELL_PROJOBS": "Check out ProJobs",
    "FRAME_NAVIGATION_DISCO": "Discover",
    "FRAME_NAVIGATION_FEEDBACK_AND_HELP_SUBTITLE": "What do you think about the new XING app?",
    "FRAME_NAVIGATION_FEEDBACK_AND_HELP_TITLE": "Your feedback",
    "FRAME_NAVIGATION_JOBS": "Jobs",
    "FRAME_NAVIGATION_ME_HUB": "You",
    "FRAME_NAVIGATION_NETWORKING_ICON_TEXT": "Networking",
    "FRAME_NAVIGATION_PROJOBS_UPSELL": "Go ProJobs",
    "FRAME_NAVIGATION_SUPI": "Home",
    "FRAME_NAVIGATION_UPSELL_HEADLINE": "Upgrades for you",
    "FRAME_NAVIGATION_YOUR_PREMIUM": "Your Premium",
    "FRAME_NAVIGATION_YOUR_PROJOBS": "Your ProJobs",
    "HEADER_SKIP_NAVIGATION": "Skip navigation",
    "INSIDER_FOLLOWED_BY": "Followers",
    "INSIDER_FOLLOWED_BY_AND": "and",
    "INSIDER_FOLLOWED_BY_AND_MORE": "other people",
    "JOB_SEARCH_ALERTS_ALERT_NAME_ALL": "All jobs",
    "LIKERS_LIST_HEADLINE": "Members who like this:",
    "LIKE_BUTTON_LABEL": "Like",
    "MALT_FILE_UPLOAD_ERROR_MESSAGE": "Unfortunately your {filesTooBig} file exceeds the {maxSize} MB limit.",
    "MALT_FILE_UPLOAD_ERROR_MESSAGE_PLURAL": "Unfortunately these files exceed the {maxSize} MB limit: {filesTooBig}",
    "MALT_FILE_UPLOAD_NO_FILE": "No file selected yet",
    "MALT_FILE_UPLOAD_NUMBER_OF_FILES": "{numberOfFiles} Files",
    "MALT_FOOTER_COPYRIGHT_API": "© {siteoperator}  | All rights reserved",
    "MALT_IMAGE_UPLOAD_BROWSE_BUTTON": "Browse files",
    "MALT_TEST_RUBY_STYLE_TRANSLATION": "My name is {name}.",
    "MESSENGER_SHARE_COMMENT_PLACEHOLDER": "Comment on your link here.",
    "MESSENGER_SHARE_RECEIPENT_SELECTION_FORM_LABEL": "Recipients:",
    "MESSENGER_SHARE_RECEIPENT_SELECTION_PLACEHOLDER": "Enter recipients",
    "MESSENGER_SHARE_TARGET_LABEL": "Your message:",
    "MONTH_NAMES": "January,February,March,April,May,June,July,August,September,October,November,December",
    "MONTH_NAMES_SHORT": "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
    "MYXING_LOADING_ALERT_MESSAGE": "Sorry, something  went wrong. Please reload the page.",
    "NBA_BUTTON_CANCEL": "Cancel",
    "NBA_BUTTON_CHANGE_IMAGE": "Change photo",
    "NBA_BUTTON_CONTINUE": "Continue",
    "NBA_CREATE_PHOTO_CARD_BENEFIT_TEXT": "A good profile photo emphasises your professional image and will lead to more profile visitors.",
    "NBA_CREATE_PHOTO_CARD_GENERIC_ERROR": "Something went wrong. Please try again.",
    "NBA_CREATE_PHOTO_CARD_IMAGE_UPLOAD_REJECTED_MIME_TYPE": "Please select an image in either JPG, PNG or BMP format.",
    "NBA_CREATE_PHOTO_CARD_IMAGE_UPLOAD_REJECTED_SIZE": "Please select an image up to 20 MB in size.",
    "NBA_CREATE_PHOTO_CARD_IMAGE_ZOOM_MAX": "Max.",
    "NBA_CREATE_PHOTO_CARD_IMAGE_ZOOM_MIN": "Min.",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_HEADLINE": "Profile photo",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_HINT_FILE_SIZES": "Recommended resolution: 1,024 x 1,024 px",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_HINT_FILE_TYPES": "JPG, BMP, PNG (max. 20 MB)",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OPTION_CLICK": "Upload photo",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OPTION_DRAG_N_DROP": "Drag and drop your image here",
    "NBA_CREATE_PHOTO_CARD_LIGHTBOX_UPLOAD_TEXT_OR": "or",
    "NBA_CREATE_PHOTO_CARD_SUCCESS_BENEFIT_TEXT": "You can change this photo in your business card whenever you like.",
    "NBA_CREATE_PHOTO_CARD_SUCCESS_HEADLINE": "Excellent! You've changed your profile image.",
    "NOTIFICATION_CENTER_LINK": "Notifications",
    "OPTIONS_DELETE": "Yes, do it!",
    "POLLS_TIME_REMAINING_DAYS_WEB.one": "Ends tomorrow",
    "POLLS_TIME_REMAINING_DAYS_WEB.other": "Ends in {days} days",
    "POLLS_TIME_REMAINING_DAYS_WEB.zero": "Ends today",
    "PROBUSINESS_BANNER_EVENTS_GUESTLIST_BODY": "View the guest list in ProBusiness, apply powerful filters to refine it, and save relevant people as leads.",
    "PROBUSINESS_BANNER_EVENTS_GUESTLIST_BUTTON": "View guest list in ProBusiness ",
    "PROBUSINESS_BANNER_EVENTS_GUESTLIST_HEADLINE": "Looking for new leads?",
    "PROBUSINESS_BANNER_SEARCH_BODY": "View your search results in ProBusiness where you can apply powerful filters and add contacts as leads.",
    "PROBUSINESS_BANNER_SEARCH_BUTTON": "Show results in ProBusiness",
    "PROBUSINESS_BANNER_SEARCH_HEADLINE": "Generate better leads from your search results!",
    "PROBUSINESS_DROPDOWN_SHOW_RESULT": "Show in ProBusiness",
    "PROBUSINESS_NO_STATUS_AVAILABLE": "Lead status not available",
    "PROBUSINESS_SAVE_AS_LEAD": "Save as lead",
    "PROFILE_BADGES_LAYER_AMBASSADOR_COMMON_HEADLINE": "Ambassadors",
    "PROFILE_BADGES_LAYER_AMBASSADOR_COMMON_TEXT": "Ambassadors are moderators of Official XING Groups who also organise Official XING Events. There are three kinds of ambassador:",
    "PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_HEADLINE": "Industry Ambassadors",
    "PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_LINK": "Browse industry groups",
    "PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_SUB": "Moderator and proven expert in their industry",
    "PROFILE_BADGES_LAYER_AMBASSADOR_INDUSTRY_TEXT": "An Industry Ambassador specialises in a specific field of expertise. He hosts an industry group as a recognised expert of his special field. He supports and encourages the exchange of ideas and knowledge in his group and on official \"XING Live!\" events.",
    "PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_HEADLINE": "Regional Ambassadors",
    "PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_LINK": "Browse regional groups",
    "PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_SUB": "Official event organiser and local contact",
    "PROFILE_BADGES_LAYER_AMBASSADOR_REGIONAL_TEXT": "The Regional Ambassador is the point of contact for XING related questions in your region. He is moderator of an official regional group which is open to anyone interested. Regional Amassadors regularly organise events in their region to enable their group members to get to know each other.",
    "PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_HEADLINE": "Topic Ambassadors",
    "PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_LINK": "Browse topic groups",
    "PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_SUB": "On expert on the modern world of work",
    "PROFILE_BADGES_LAYER_AMBASSADOR_TOPIC_TEXT": "Topic Ambassadors are experts for modern working life. They host groups where people discuss the future of work and organise events where those discussions are continued in workshops, keynote presentations, and meetings.",
    "PROFILE_BADGES_LAYER_MODERATOR_SUB": "Contact person and mediator for the group",
    "PROFILE_BADGES_LAYER_PREMIUM_LINK": "View all Premium benefits",
    "PROFILE_BADGES_LAYER_PREMIUM_SUB": "Handy advanced features for your business and career",
    "PROFILE_BUSINESS_CARD_BADGE_AMBASSADOR": "Ambassador",
    "PROFILE_BUSINESS_CARD_BADGE_AMBASSADOR_TEXT": "How do I become an Ambassador?",
    "PROFILE_BUSINESS_CARD_BADGE_BASIC": "Basic",
    "PROFILE_BUSINESS_CARD_BADGE_BASIC_TOOLTIP": "Discover Premium",
    "PROFILE_BUSINESS_CARD_BADGE_INDUSTRY_AMBASSADOR": "Industry Ambassador",
    "PROFILE_BUSINESS_CARD_BADGE_MODERATOR": "Moderator",
    "PROFILE_BUSINESS_CARD_BADGE_MODERATOR_TEXT": "How do I become a moderator?",
    "PROFILE_BUSINESS_CARD_BADGE_PREMIUM": "Premium",
    "PROFILE_BUSINESS_CARD_BADGE_PREMIUM_TEXT": "How do I become a Premium Member?",
    "PROFILE_BUSINESS_CARD_BADGE_PREMIUM_TOOLTIP": "Premium member",
    "PROFILE_BUSINESS_CARD_BADGE_REGIONAL_AMBASSADOR": "Ambassador",
    "PROFILE_BUSINESS_CARD_BADGE_TOPIC_AMBASSADOR": "Topic Ambassador",
    "PROFILE_BUSINESS_CARD_BADGE_XPERT": "Xpert",
    "PROFILE_BUSINESS_CARD_BADGE_XPERT_TEXT": "How do I become an Xpert?",
    "PROGRESS_BAR_LOADING_PERCENTAGE": "{percentage}% finished",
    "REMEMBER_PASSWORD": "Remember me",
    "SEARCH_TOPICS_TAB_SEARCH_BUTTON": "Search",
    "SEARCH_TOPICS_TAB_TITLE": "Topics",
    "SE_ALL_VIEW_SEARCH_RESULTS": "{max} of {total} results",
    "SE_EXTENDED_SEARCH_LIST_DESCRIPTION": "What are you looking for?",
    "SE_FILTER_JOBS_PROJOB_LABEL": "Jobs posted by top recruiters",
    "SE_JOBS_SUGGESTED_NAME_LOCATION": "Jobs in {location}",
    "SE_LABEL_COMMUNITIES": "Groups",
    "SE_LABEL_COMPANIES": "Companies",
    "SE_LABEL_EVENTS": "Events",
    "SE_LABEL_JOBS": "Jobs",
    "SE_LABEL_MEMBERS": "Members",
    "SE_LABEL_NEWS": "News",
    "SE_OFFICIAL_XING_GROUPS": "Official XING Groups",
    "SE_PREMIUM_FLAG_ALTTEXT": "Only Premium members",
    "SE_SELECT_MEMBERS": "Members",
    "SE_SHOW_ALL_RESULTS": "All results",
    "SHARERS_BACK_TO_POST_LINK": "Back",
    "SHARERS_LIST_BACK_LINK": "Back",
    "SHARER_LIST_HEADLINE_MEMBERS": "{total} members shared this.",
    "SHARER_LIST_HEADLINE_MULTIPLE_MEMBERS": "{total} members shared this.",
    "SHARER_LIST_HEADLINE_ONE_MEMBER": "One member shared this.",
    "SHARER_LIST_ITEM_GO_TO_POST_LINK": "View post",
    "SHARER_LIST_POST_NOTIFICATION": "Some posts aren't visible here due to users' privacy settings.",
    "SHARE_ACTIONSHEET_HEADER": "Share this post",
    "SHARE_BOX_DEFAULT_ERROR_MESSAGE": "Sorry, something went wrong. Please try again later.",
    "SHARE_BOX_HEADER": "Share on XING",
    "SHARE_BOX_PLACEHOLDER": "Comment on your link here.",
    "SHARE_BOX_SHARE_SUCCESS": "Thanks for sharing!",
    "SHARE_BOX_SUBMIT_SHARE": "Share",
    "SHARE_BUTTON_LABEL": "Share",
    "SHARE_CANCEL_BUTTON": "Cancel",
    "SHARE_CHAT_CONTACTS": "Select a contact",
    "SHARE_CHAT_HINT": "Your recipients will each receive a message.",
    "SHARE_COUNTER_LABEL": "{counter, plural,\n    =1 {1 share}\n    other {# shares}\n}",
    "SHARE_FEEDBACK_GROUPS_BUTTON": "View post",
    "SHARE_FEEDBACK_GROUPS_MESSAGE": "{item} shared in the {group} group",
    "SHARE_FEEDBACK_INSIDERARTICLE_LABEL": "Article",
    "SHARE_FEEDBACK_JOB_LABEL": "Job",
    "SHARE_FEEDBACK_KLARTEXT_LABEL": "Post",
    "SHARE_FEEDBACK_LINK_LABEL": "Link",
    "SHARE_FEEDBACK_MESSENGER_BUTTON": "View message",
    "SHARE_FEEDBACK_MESSENGER_MESSAGE": "{item} shared via XING message",
    "SHARE_FEEDBACK_NEWS_LABEL": "Article",
    "SHARE_FEEDBACK_PICTURE_LABEL": "Image",
    "SHARE_FEEDBACK_POST_LABEL": "Post",
    "SHARE_FEEDBACK_STARTPAGE_BUTTON": "View post",
    "SHARE_FEEDBACK_STARTPAGE_MESSAGE": "{item} shared on the homepage",
    "SHARE_FEEDBACK_VIDEO_LABEL": "Video",
    "SHARE_FETCH_ERROR": "We're having problems connecting right now. Please try again later.",
    "SHARE_FETCH_ERROR_COUNTDOWN": "Trying again in {countdown} seconds.",
    "SHARE_FETCH_ERROR_RETRY": "Try again",
    "SHARE_MOST_RECENT_CHATS": "Chats",
    "SHARE_PREVIEW_ERROR_HEADLINE": "Preview currently unavailable.",
    "SHARE_QUICKSHARE_TO_STARTPAGE": "In the feed",
    "SHARE_TEXT_BOX_LENGTH_ERROR": "{delta, plural, \none {Sorry, that's 1 character too many.} \nother {Sorry, that's # characters too many.}\n}",
    "SHARE_TO_GROUPS": "In a group",
    "SHARE_TO_GROUPS_AUTOCOMPLETE_FORUMS": "Forum",
    "SHARE_TO_GROUPS_AUTOCOMPLETE_GROUPS": "Group",
    "SHARE_TO_GROUPS_SELECTION_LABEL": "Select group:",
    "SHARE_TO_GROUPS_TEXT_PLACEHOLDER": "Comment on your link here.",
    "SHARE_TO_GROUPS_THREAD_LABEL": "Your post:",
    "SHARE_TO_GROUPS_TITLE_PLACEHOLDER": "Title",
    "SHARE_TO_MESSENGER": "Via private message",
    "SHARE_TO_MESSENGER_EMPTY_STATE": "Unfortunately there weren't any hits for {query}.",
    "SHARE_TO_STARTPAGE": "In the feed with a comment",
    "SHARE_USER_SUBTEXT_GROUPS": "Share with a group",
    "SHARE_USER_SUBTEXT_MESSAGE": "Share in a chat message",
    "SHARE_USER_SUBTEXT_STARTPAGE": "Share on XING",
    "SOCIAL_ADD_CONTACT": "Add as contact",
    "SOCIAL_BAR_VIEW_COUNTER_DESCRIPTION": "Click on the eye icon to find out your visibility. ",
    "SOCIAL_BAR_VIEW_COUNTER_HEADING": "Your visibility",
    "SOCIAL_BAR_VIEW_COUNTER_SEEN": "For instance, this post was seen <strong>{views} times</strong>.",
    "SOCIAL_BAR_VIEW_COUNTER_TIP": "Want to increase your reach? Find out how to <a>boost your visibility on XING</a>.",
    "SOCIAL_COMMENTS_COUNTER_LABEL": "{counter, plural,\n    =0 {Be the first to comment:}\n    =1 {1 comment} \n    other {# comments}\n}",
    "SOCIAL_COMMENTS_CREATE_MENTION_PLACEHOLDER": "Comment here and use @ to mention others",
    "SOCIAL_COMMENTS_CREATE_PLACEHOLDER": "Enter your comment here...",
    "SOCIAL_COMMENTS_DELETE_DIALOG_CANCEL": "Cancel",
    "SOCIAL_COMMENTS_DELETE_DIALOG_CONFIRM": "Delete",
    "SOCIAL_COMMENTS_DELETE_DIALOG_CREATOR_TEXT": "Not entirely happy with what you wrote? Edit or retract it if you like.",
    "SOCIAL_COMMENTS_DELETE_DIALOG_HEADLINE": "Delete this comment?",
    "SOCIAL_COMMENTS_DELETE_DIALOG_TEXT": "You won't be able to undo this.",
    "SOCIAL_COMMENTS_DELETE_MENTION_LABEL": "Block mentions",
    "SOCIAL_COMMENTS_FORM_PLACEHOLDER": "Comment and use @ to mention",
    "SOCIAL_COMMENTS_LOAD_MORE": "Show more comments",
    "SOCIAL_COMMENTS_PREVIOUS_COMMENTS": "Show previous comments",
    "SOCIAL_COMMENTS_PREVIOUS_REPLIES": "Show previous replies",
    "SOCIAL_COMMENTS_REPLY_PLACEHOLDER": "Reply to {authorName}",
    "SOCIAL_COMMENT_BUTTON_LABEL": "Comment",
    "SOCIAL_COMMENT_COUNTER_LABEL": "{amount} comments",
    "SOCIAL_COMMENT_CREATE_BUTTON_LABEL": "Comment",
    "SOCIAL_COMMENT_DELETED_MESSAGE": "Comment deleted.",
    "SOCIAL_COMMENT_DELETE_LABEL": "Delete comment",
    "SOCIAL_COMMENT_EDIT_LABEL": "Edit comment",
    "SOCIAL_COMMENT_ERROR_TOO_LARGE": "Please limit your comment to 20,000 characters.",
    "SOCIAL_COMMENT_LIST_EMPTY_HEADLINE": "Have your say",
    "SOCIAL_COMMENT_LIST_EMPTY_SUBLINE": "Add your voice to the conversation.",
    "SOCIAL_COMMENT_OVERFLOW_ERROR": "{amount} characters",
    "SOCIAL_COMMENT_REPORT_LABEL": "Report comment",
    "SOCIAL_COMMENT_WAS_EDITED": "Edited",
    "SOCIAL_GENERIC_REPORT": "Report",
    "SOCIAL_LIKERS_LIST_HEADLINE": "Members who like this:",
    "SOCIAL_LIKERS_LIST_POST_NOTIFICATION": "Some likes aren't visible here due to users' privacy settings.",
    "SOCIAL_PMBX_COMMENT_CONTENT_URL": "Content",
    "SOCIAL_PMBX_COMMENT_DATE": "Date",
    "SOCIAL_PMBX_COMMENT_HEADING": "Your comments and replies",
    "SOCIAL_PMBX_COMMENT_MESSAGE": "Your comment",
    "SOCIAL_PMBX_COMMENT_ORIGIN": "Where did you comment?",
    "SOCIAL_PMBX_COMMENT_TITLE": "Your comments and replies",
    "SOCIAL_PMBX_COMMENT_USER_AGENT": "User agent",
    "SOCIAL_PMBX_DESCRIPTION": "Posts on XING you liked, shared, mentioned someone or commented on",
    "SOCIAL_PMBX_REACTION_CONTENT_URL": "Content",
    "SOCIAL_PMBX_REACTION_DATE": "Date",
    "SOCIAL_PMBX_REACTION_LIKE": "Posts you like",
    "SOCIAL_PMBX_REACTION_ORIGIN": "Where did you react to this post?",
    "SOCIAL_PMBX_REACTION_TITLE": "Posts you reacted to\t ",
    "SOCIAL_PMBX_REACTION_USER_AGENT": "User agent",
    "SOCIAL_PMBX_SHARE_CONTENT_URL": "Content",
    "SOCIAL_PMBX_SHARE_DATE": "Date",
    "SOCIAL_PMBX_SHARE_GROUPS": "Posts you shared with a group",
    "SOCIAL_PMBX_SHARE_MESSAGE": "What you wrote",
    "SOCIAL_PMBX_SHARE_MESSENGER": "Posts you shared as a private message",
    "SOCIAL_PMBX_SHARE_ORIGIN": "Where did you share this post?",
    "SOCIAL_PMBX_SHARE_STARTPAGE": "Posts you shared on XING",
    "SOCIAL_PMBX_SHARE_TITLE": "Posts you shared",
    "SOCIAL_PMBX_SHARE_USER_AGENT": "User agent",
    "SOCIAL_PMBX_TITLE": "Interactions",
    "SOCIAL_REPLIES_LOAD_MORE": "Show more replies",
    "SOCIAL_REPLY_BUTTON": "Reply",
    "SOCIAL_REPLY_DISCARD_MESSAGE": "Really discard this comment?",
    "SOCIAL_REPLY_LOAD_MORE": "Show 1 more reply",
    "SOCIAL_REPLY_LOAD_MULTIPLE": "Show more comments",
    "SOCIAL_REPLY_POST_BUTTON": "Reply",
    "SOCIAL_SEND_MESSAGE": "Write a message",
    "SOCIAL_SHARERS_LIST_HEADLINE_MEMBERS": "Members who shared this:",
    "SOCIAL_SHARERS_LIST_ITEM_GO_TO_POST_LINK": "View post",
    "SOCIAL_SHARERS_LIST_POST_NOTIFICATION": "Some posts aren't visible here due to users' privacy settings.",
    "SOCIAL_SHARE_COUNTER_LABEL": "{amount} shares",
    "SOCIAL_SHARE_EXTERNAL_LINK_ERROR": "This link can't be shared.",
    "SOCIAL_SHOW_MORE": "Show more",
    "SOCIAL_SOCIAL_PROOF_LABEL": "{counter, plural,\n    =1 {1 like:} \n    other {# likes:}\n}",
    "SOCIAL_UPDATE_COMMENT_LABEL": "Save",
    "TOP_BAR_BACK_BUTTON_ARIA_LABEL": "Back",
    "TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM": "Back to {section}",
    "TOP_BAR_PROFILE_FILLING_INDICATOR_TITLE": "Profile",
    "UNIVERSAL_CAREER_LEVEL_LIST": "1. Student/Intern\n2. Entry level\n3. Professional/experienced\n4. Manager/supervisor\n5. Executive (VP, SVP, etc.)\n6. Senior executive (CEO, etc.)",
    "UNIVERSAL_WORKEXPERIENCE_DISCIPLINE_LIST": "1001. Analysis & Statistics\n1002. Administration\n1003. Consulting\n4. Controlling & Planning\n1004. Customer Service\n1005. Purchasing, Materials Management & Logistics\n1006. Finance, Accounting & Controlling\n1007. Teaching, R&D\n1008. Health, Medical & Social\n1009. Graphic Design & Architecture\n1010. Engineering & Technical\n1011. IT & Software Development\n1012. Management & Corporate Development\n1013. Marketing & Advertising\n1014. HR\n1015. PR & Journalism\n1016. Production & Manufacturing\n1017. Product Management\n1018. Project Management\n1019. Process Planning & QA\n1020. Law\n1021. Sales & Commerce\n1022. Other Disciplines",
    "UPLOADER_ADD_IMAGE": "Add image",
    "UPLOADER_ADD_VIDEO": "Add video",
    "UPLOADER_CANCEL_UPLOAD": "Cancel upload",
    "UPLOADER_ERROR_BODY": "Please try again.",
    "UPLOADER_ERROR_HEADLINE": "Sorry, that didn't work.",
    "UPLOADER_IMAGE_LOADING_HEADLINE": "Uploading image...",
    "UPLOADER_TRY_AGAIN": "Try again",
    "UPLOADER_VIDEO_LOADED_BODY": "We are now processing it to make sure it'll look great. This may take a few minutes – but don't let that keep you from posting or sharing it now.\n\n",
    "UPLOADER_VIDEO_LOADED_HEADLINE": "Your video has been uploaded.",
    "UPLOADER_VIDEO_LOADING_HEADLINE": "Uploading video...",
    "USER_FLAG_BUTTON_ARIA_LABEL": "{type} membership badge",
    "USER_FLAG_ERROR": "This information isn't available right now.<br>\nPlease try again later.",
    "USER_FLAG_ERROR_PARAGRAPH_1": "This information isn't available right now.",
    "USER_FLAG_ERROR_PARAGRAPH_2": "Please try again later.",
    "USER_FLAG_LOADING": "Loading...",
    "VERTICAL_NAVIGATION_BUSINESS_SOLUTIONS": "Business solutions",
    "VERTICAL_NAVIGATION_BUSINESS_SOLUTIONS_SHORT": "Business",
    "VERTICAL_NAVIGATION_CAMPUS": "Campus",
    "VERTICAL_NAVIGATION_COMMUNITIES": "Groups",
    "VERTICAL_NAVIGATION_COMPANIES": "Companies",
    "VERTICAL_NAVIGATION_CONTACTS": "Your network",
    "VERTICAL_NAVIGATION_CONTACTS_SHORT": "Network",
    "VERTICAL_NAVIGATION_CONTENT_PAGES": "News",
    "VERTICAL_NAVIGATION_EVENTS": "Events",
    "VERTICAL_NAVIGATION_FEEDBACK_AND_HELP_TITLE": "The new XING app: Feedback & Help",
    "VERTICAL_NAVIGATION_FEEDBACK_AND_HELP_TITLE_SHORT": "New app",
    "VERTICAL_NAVIGATION_FOOTER_ADVERTISE": "Advertise on XING",
    "VERTICAL_NAVIGATION_FOOTER_AMIANDO": "Ticketing and event promotion",
    "VERTICAL_NAVIGATION_FOOTER_ANDROID": "Android",
    "VERTICAL_NAVIGATION_FOOTER_ANSCHREIBEN": "Cover letter editor",
    "VERTICAL_NAVIGATION_FOOTER_APPS": "Apps",
    "VERTICAL_NAVIGATION_FOOTER_APP_GALLERY": "Mobile & desktop apps",
    "VERTICAL_NAVIGATION_FOOTER_BEWERBUNG": "Job application tips",
    "VERTICAL_NAVIGATION_FOOTER_CAMPUS": "Campus",
    "VERTICAL_NAVIGATION_FOOTER_CAREER": "Careers",
    "VERTICAL_NAVIGATION_FOOTER_CAREER_GUIDE": "Career Guide",
    "VERTICAL_NAVIGATION_FOOTER_COACHES": "Coaches + Trainers",
    "VERTICAL_NAVIGATION_FOOTER_COMMUNITY": "Community",
    "VERTICAL_NAVIGATION_FOOTER_COMPANIES": "Companies",
    "VERTICAL_NAVIGATION_FOOTER_COMPANY": "Company",
    "VERTICAL_NAVIGATION_FOOTER_DEVBLOG": "Devblog",
    "VERTICAL_NAVIGATION_FOOTER_DEVELOPER_PORTAL": "Developers",
    "VERTICAL_NAVIGATION_FOOTER_DOWNLOAD": "Downloads",
    "VERTICAL_NAVIGATION_FOOTER_EVENTS": "Events",
    "VERTICAL_NAVIGATION_FOOTER_GEHALTSVERGLEICH": "Salary check",
    "VERTICAL_NAVIGATION_FOOTER_GROUPS": "Groups",
    "VERTICAL_NAVIGATION_FOOTER_HELP": "Help & Contact",
    "VERTICAL_NAVIGATION_FOOTER_IMPRINT": "About this site",
    "VERTICAL_NAVIGATION_FOOTER_INVESTOR_RELATIONS": "Investor Relations",
    "VERTICAL_NAVIGATION_FOOTER_IPHONE_IPAD": "iPhone & iPad",
    "VERTICAL_NAVIGATION_FOOTER_JOBS_DIRECTORY": "Jobs Directory",
    "VERTICAL_NAVIGATION_FOOTER_JOB_MARKET": "Jobs",
    "VERTICAL_NAVIGATION_FOOTER_KUNUNU": "Employers",
    "VERTICAL_NAVIGATION_FOOTER_LEBENSLAUF": "CV editor",
    "VERTICAL_NAVIGATION_FOOTER_LOGINWXING": "Log in with XING",
    "VERTICAL_NAVIGATION_FOOTER_MEMBERSHIPS": "Memberships",
    "VERTICAL_NAVIGATION_FOOTER_MEMBERSHIP_CANCELLATION": "Cancel membership",
    "VERTICAL_NAVIGATION_FOOTER_MOBILE": "Mobile",
    "VERTICAL_NAVIGATION_FOOTER_NEWWORKSE": "New Work SE",
    "VERTICAL_NAVIGATION_FOOTER_PEOPLE_DIRECTORY": "Member directory",
    "VERTICAL_NAVIGATION_FOOTER_PRAKTIKUM_GUIDE": "Praktikum-Guide",
    "VERTICAL_NAVIGATION_FOOTER_PREMIUM_MEMBERSHIP": "Premium",
    "VERTICAL_NAVIGATION_FOOTER_PRESS": "Press",
    "VERTICAL_NAVIGATION_FOOTER_PRIVACY": "Privacy at XING",
    "VERTICAL_NAVIGATION_FOOTER_PRIVACY_POLICY": "Privacy Policy",
    "VERTICAL_NAVIGATION_FOOTER_PROJOBS_MEMBERSHIP": "ProJobs",
    "VERTICAL_NAVIGATION_FOOTER_PRO_BUSINESS_MEMBERSHIP": "ProBusiness",
    "VERTICAL_NAVIGATION_FOOTER_RESOURCES": "Resources",
    "VERTICAL_NAVIGATION_FOOTER_SERVICES": "Additional services",
    "VERTICAL_NAVIGATION_FOOTER_TALENT_MANAGER": "Recruiting with XING",
    "VERTICAL_NAVIGATION_FOOTER_TANDC": "Terms & Conditions",
    "VERTICAL_NAVIGATION_FOOTER_TRACKING": "Tracking",
    "VERTICAL_NAVIGATION_FOOTER_WINDOWS_10": "Windows 10",
    "VERTICAL_NAVIGATION_FOOTER_XAS": "Post an ad",
    "VERTICAL_NAVIGATION_FOOTER_XING_AG": "New Work SE",
    "VERTICAL_NAVIGATION_FOOTER_XING_NEWS": "XING News",
    "VERTICAL_NAVIGATION_FOOTER_XING_SHARE": "XING share button",
    "VERTICAL_NAVIGATION_FOOTER_YOUR_XING": "Main Sections",
    "VERTICAL_NAVIGATION_HEADER_BRAND_MANAGER_EDITOR": "BrandManager",
    "VERTICAL_NAVIGATION_HEADER_HELP": "Help",
    "VERTICAL_NAVIGATION_HEADER_MANAGE_JOBS": "Job ads",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_ADS": "AdManager",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_COACH_PROFILE": "Coach profile",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_INVOICES": "Invoices & Accounts",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_LOGOUT": "Log out",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_MEMBERSHIPS_INVOICES": "Memberships & invoices",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_PREMIUM_MEMBERSHIP": "Premium",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_PROBUSINESS_MEMBERSHIP": "ProBusiness",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_MEMBERSHIP": "ProJobs",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_PROJOBS_SETTINGS": "ProJobs settings",
    "VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS": "Settings",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_ALL_RESULTS": "View all",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_BUTTON": "Search",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_COMMUNITIES": "Groups",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_COMPANIES_PROFILE": "Employer",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_EVENTS": "Events",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_JOBS": "Jobs",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_MEMBERS": "Members",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_NEWS": "Industry News",
    "VERTICAL_NAVIGATION_HEADER_SEARCH_PLACEHOLDER": "Enter a job title, name or keyword",
    "VERTICAL_NAVIGATION_HEADER_SEAT_MANAGER_ADMIN": "LicenceManager",
    "VERTICAL_NAVIGATION_HEADER_XTM": "TalentManager",
    "VERTICAL_NAVIGATION_MESSAGES": "Messages",
    "VERTICAL_NAVIGATION_MORE": "More",
    "VERTICAL_NAVIGATION_MYPROJOBS": "ProJobs",
    "VERTICAL_NAVIGATION_OTHER_SERVICES": "Other services",
    "VERTICAL_NAVIGATION_PREMIUM_BENEFITS": "Premium",
    "VERTICAL_NAVIGATION_PREMIUM_BENEFITS_FOR_BASIC_USER": "Go Premium",
    "VERTICAL_NAVIGATION_PREMIUM_BENEFITS_SHORT": "Premium",
    "VERTICAL_NAVIGATION_PROBUSINESS": "ProBusiness",
    "VERTICAL_NAVIGATION_PROJOBS_BENEFITS_FOR_BASIC_USER": "Go ProJobs",
    "VERTICAL_NAVIGATION_PROJOBS_UPSELL": "Check out ProJobs",
    "VERTICAL_NAVIGATION_PROJOBS_UPSELL_SHORT": "ProJobs",
    "VERTICAL_NAVIGATION_STARTPAGE": "Home",
    "VIDEOS_AUTOPLAY": "Autoplay",
    "VIDEOS_SETTINGS": "Autoplay videos?",
    "VIDEOS_WATCH_VIDEO": "Watch video",
    "VIDEO_ENCODING_HEADLINE": "Encoding your video...",
    "VIDEO_ENCODING_MESSAGE": "Please bear with us as this may take a few minutes.",
    "VIEW_PAGE_IN_CHINESE": "View this page in Chinese",
    "VIEW_PAGE_IN_DUTCH": "View this page in Dutch",
    "VIEW_PAGE_IN_ENGLISH": "View this page in English",
    "VIEW_PAGE_IN_FINNISH": "View this page in Finnish",
    "VIEW_PAGE_IN_FRENCH": "View this page in French",
    "VIEW_PAGE_IN_GERMAN": "View this page in German",
    "VIEW_PAGE_IN_HUNGARIAN": "View this page in Hungarian",
    "VIEW_PAGE_IN_ITALIAN": "View this page in Italian",
    "VIEW_PAGE_IN_JAPANESE": "View this page in Japanese",
    "VIEW_PAGE_IN_KOREAN": "View this page in Korean",
    "VIEW_PAGE_IN_POLISH": "View this page in Polish",
    "VIEW_PAGE_IN_PORTUGUESE": "View this page in Portuguese",
    "VIEW_PAGE_IN_RUSSIAN": "View this page in Russian",
    "VIEW_PAGE_IN_SPANISH": "View this page in Spanish",
    "VIEW_PAGE_IN_SWEDISH": "View this page in Swedish",
    "VIEW_PAGE_IN_TURKISH": "View this page in Turkish",
    "XBP_NAVIGATION_ENTRY_LINK": "Manage Business Pages",
    "XDS_BACK_TO_TOP_BUTTON": "Back to top",
    "XDS_FLAG_AMBASSADOR_DIVERSE": "Ambassador",
    "XDS_FLAG_AMBASSADOR_FEMALE": "Ambassador",
    "XDS_FLAG_AMBASSADOR_MALE": "Ambassador",
    "XDS_FLAG_AMBASSADOR_NEUTRAL": "Ambassador",
    "XDS_FLAG_BASIC_DIVERSE": "Basic",
    "XDS_FLAG_BASIC_FEMALE": "Basic",
    "XDS_FLAG_BASIC_MALE": "Basic",
    "XDS_FLAG_BASIC_NEUTRAL": "Basic",
    "XDS_FLAG_BETA_DIVERSE": "Beta",
    "XDS_FLAG_BETA_FEMALE": "Beta",
    "XDS_FLAG_BETA_MALE": "Beta",
    "XDS_FLAG_BETA_NEUTRAL": "Beta",
    "XDS_FLAG_INSIDER_DIVERSE": "Insider",
    "XDS_FLAG_INSIDER_FEMALE": "Insider",
    "XDS_FLAG_INSIDER_MALE": "Insider",
    "XDS_FLAG_INSIDER_NEUTRAL": "Insider",
    "XDS_FLAG_MODERATOR_DIVERSE": "Moderator",
    "XDS_FLAG_MODERATOR_FEMALE": "Moderator",
    "XDS_FLAG_MODERATOR_MALE": "Moderator",
    "XDS_FLAG_MODERATOR_NEUTRAL": "Moderator",
    "XDS_FLAG_NEW_DIVERSE": "New",
    "XDS_FLAG_NEW_FEMALE": "New",
    "XDS_FLAG_NEW_MALE": "New",
    "XDS_FLAG_NEW_NEUTRAL": "New",
    "XDS_FLAG_PREMIUM_DIVERSE": "Premium",
    "XDS_FLAG_PREMIUM_FEMALE": "Premium",
    "XDS_FLAG_PREMIUM_MALE": "Premium",
    "XDS_FLAG_PREMIUM_NEUTRAL": "Premium",
    "XDS_FLAG_PROBUSINESS_DIVERSE": "ProBusiness",
    "XDS_FLAG_PROBUSINESS_FEMALE": "ProBusiness",
    "XDS_FLAG_PROBUSINESS_MALE": "ProBusiness",
    "XDS_FLAG_PROBUSINESS_NEUTRAL": "ProBusiness",
    "XDS_FLAG_PROCOACH_DIVERSE": "ProCoach",
    "XDS_FLAG_PROCOACH_FEMALE": "ProCoach",
    "XDS_FLAG_PROCOACH_MALE": "ProCoach",
    "XDS_FLAG_PROCOACH_NEUTRAL": "ProCoach",
    "XDS_FLAG_PROJOBS_DIVERSE": "ProJobs",
    "XDS_FLAG_PROJOBS_FEMALE": "ProJobs",
    "XDS_FLAG_PROJOBS_MALE": "ProJobs",
    "XDS_FLAG_PROJOBS_NEUTRAL": "ProJobs",
    "XDS_FLAG_PROTRAINER_DIVERSE": "ProTrainer",
    "XDS_FLAG_PROTRAINER_FEMALE": "ProTrainer",
    "XDS_FLAG_PROTRAINER_MALE": "ProTrainer",
    "XDS_FLAG_PROTRAINER_NEUTRAL": "ProTrainer",
    "XINGID_ADD_LEAD_SUCCESS": "{displayName} added to leads",
    "XINGID_REMOVE_LEAD_SUCCESS": "{displayName} removed from leads",
    "XING_FAQ_GUIDE_URL": "https://faq.xing.com/en/node/68237",
    "XWS_100_APP_PERMISSION_VENDOR_VIDEOS": "Access XING videos vendor API",
    "XWS_100_APP_PERMISSION_VENDOR_VIDEOS_HEADING": "XING videos API",
    "X_UI_HEADER_REGISTER": "Sign up",
    "X_UI_NAVIGATION_LOGIN_FRAME_FALLBACK": "Log in",
    "ERROR_REGISTER_EMAIL_MAX_CHANGE": "You can change your e-mail address up to 10 times.",
    "JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_ONE": "Over 1 million jobs",
    "JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_THREE": "Job search webinars",
    "JOBS_LANDING_LO_MARKETING_SEC_ONE_TEXT_TWO": "More than 21 million members",
    "WELCOME_APP_MINIREG_ERROR_EMAIL": "Please check the e-mail address you entered.",
    "WELCOME_APP_MINIREG_ERROR_FIRST_NAME": "Please enter your first name here.",
    "WELCOME_APP_MINIREG_ERROR_LAST_NAME": "Please enter your last name here.",
    "WELCOME_APP_MINIREG_ERROR_PASSWORD_EMAIL": "For your own security you should choose a password different to your e-mail address.",
    "WELCOME_APP_MINIREG_ERROR_PASSWORD_LAST_NAME": "For your own security you should choose a password different to your last name.",
    "WELCOME_APP_MINIREG_ERROR_PASSWORD_TOO_LONG": "Please choose a password containing no more than 1,024 characters.",
    "WELCOME_APP_MINIREG_ERROR_PHONE": "Please check your phone number.",
    "WELCOME_APP_MINIREG_ERROR_TANDC": "Please confirm that you accept the Terms & Conditions and the Privacy Policy.",
    "WELCOME_COMPLETION_HEADLINE": "Welcome to XING!",
    "WELCOME_COMPLETION_PAGE_META_TITLE": "Welcome to XING! | XING",
    "WELCOME_COMPLETION_SUBLINE": "Just provide a few details to complete your registration:",
    "WELCOME_COMPLETION_SUBLINE_B": "Your profile is ready to go. All we need now is your name:",
    "WELCOME_COMPLETION_SUBLINE_C": "Find familiar faces on XING. Just enter your name now:",
    "WELCOME_COMPLETION_SUBLINE_D": "Just add your name now to start connecting with friends and colleagues:",
    "WELCOME_COMPLETION_SUBLINE_E": "We're your professional companion. Who are you?",
    "WELCOME_CONFIRMATION_DEFAULT_BOUNCED_BODY": "Please check your e-mail address again. If you're sure it's right, get in touch with our {link} team.",
    "WELCOME_CONFIRMATION_DEFAULT_BOUNCED_CTA": "Change e-mail address",
    "WELCOME_CONFIRMATION_DEFAULT_BOUNCED_HEADER": "E-mail undeliverable",
    "WELCOME_CONFIRMATION_DEFAULT_BOUNCED_SUPPORT_LINK_TEXT": "support",
    "WELCOME_CONFIRMATION_DEFAULT_BUTTON": "Not received an e-mail?",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_1": "Confirm your account now:",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_1_C": "We just sent a link to {email}.*",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_1_D": "We just sent a link to {email}.*",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_2": "We just sent a link to {email}.*",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_2_C": "Click on it to confirm your e-mail address.",
    "WELCOME_CONFIRMATION_DEFAULT_COPY_2_D": "Click on it to complete your registration.",
    "WELCOME_CONFIRMATION_DEFAULT_HEADLINE": "Almost there!",
    "WELCOME_CONFIRMATION_DEFAULT_HEADLINE_C": "You have mail:",
    "WELCOME_CONFIRMATION_DEFAULT_HEADLINE_D": "Confirm registration",
    "WELCOME_CONFIRMATION_DEFAULT_SUFFIX": "* E-mail not arrived? Please check your spam folder. If you're using Gmail, please also check your 'Advertising' and 'Social networks' folders.",
    "WELCOME_CONFIRMATION_EDIT_EMAIL_BUTTON": "Send confirmation e-mail",
    "WELCOME_CONFIRMATION_EDIT_EMAIL_COPY": "If not, just update it below:",
    "WELCOME_CONFIRMATION_EDIT_EMAIL_HEADLINE": "Is this your e-mail address?",
    "WELCOME_CONFIRMATION_EDIT_EMAIL_LABEL": "E-mail",
    "WELCOME_CONFIRMATION_EDIT_EMAIL_REQUEST_ERROR": "Sorry, something went wrong. Please try again.",
    "WELCOME_CONFIRMATION_EMAIL_RESENT_BUTTON": "Still not received an e-mail?",
    "WELCOME_CONFIRMATION_EMAIL_RESENT_COPY_1": "We just sent a new confirmation link to {email}",
    "WELCOME_CONFIRMATION_EMAIL_RESENT_COPY_2": "Please click on the link in your e-mail to complete registration. ",
    "WELCOME_CONFIRMATION_EMAIL_RESENT_HEADLINE": "E-mail sent!",
    "WELCOME_CONFIRMATION_PAGE_META_TITLE": "Activate your account | XING",
    "WELCOME_GDPR_IMG_ALT": "Privacy at XING is GDPR-compliant",
    "WELCOME_INVITATION_HEADLINE": "{firstname} {lastname} wants you to join XING.",
    "WELCOME_INVITATION_SUBLINE": "Register now to add {firstname} as your first XING contact.",
    "WELCOME_LANGUAGE_PAGE_META_TITLE": "Choose language | XING",
    "WELCOME_LANGUAGE_SELECT_ENGLISH_LABEL": "English",
    "WELCOME_LANGUAGE_SELECT_FRENCH_LABEL": "Français",
    "WELCOME_LANGUAGE_SELECT_GERMAN_LABEL": "Deutsch",
    "WELCOME_LANGUAGE_SELECT_HEADLINE": "Please select your preferred language:",
    "WELCOME_LANGUAGE_SELECT_ITALIAN_LABEL": "Italiano",
    "WELCOME_LANGUAGE_SELECT_SPANISH_LABEL": "Español",
    "WELCOME_LOGIN_FORM_EMAIL_REQUIRED_ERROR": "Please enter your e-mail address here.",
    "WELCOME_LOGIN_FORM_FIRSTNAME_REQUIRED_ERROR": "Please enter your first name here.",
    "WELCOME_LOGIN_FORM_LASTNAME_REQUIRED_ERROR": "Please enter your last name here.",
    "WELCOME_LOGIN_FORM_PASSWORD_REQUIRED_ERROR": "Please enter your password here.",
    "WELCOME_LOGIN_FORM_PASSWORD_REQURED_ERROR": "Please enter your password here.",
    "WELCOME_LOGIN_FORM_REMEMEMBER_ME_LABEL": "Remember me",
    "WELCOME_LOGIN_FORM_SUBMIT_REQUEST_ERROR": "Sorry, something went wrong. Please try again.",
    "WELCOME_LOGIN_FORM_USERNAME_REQUIRED_ERROR": "Please enter your e-mail address here.",
    "WELCOME_LOGIN_HEADLINE": "Welcome back!",
    "WELCOME_LOGIN_PASSWORD_LABEL": "Password",
    "WELCOME_LOGIN_PROBLEMS_LOGGING_IN": "Having problems logging in?",
    "WELCOME_LOGIN_SUBMIT_LABEL": "Log in",
    "WELCOME_LOGIN_USERNAME_LABEL": "E-mail",
    "WELCOME_REGISTRATION_CTA_REG_7503_E": "Create new profile",
    "WELCOME_REGISTRATION_CTA_REG_7504_A": "Continue",
    "WELCOME_REGISTRATION_CTA_REG_7504_B": "Sign up",
    "WELCOME_REGISTRATION_CTA_REG_7504_C": "Sign up now",
    "WELCOME_REGISTRATION_CTA_REG_7504_D": "Create profile",
    "WELCOME_REGISTRATION_CTA_REG_7504_E": "Join XING",
    "WELCOME_REGISTRATION_DATAPROTECTION_LABEL": "Privacy Policy",
    "WELCOME_REGISTRATION_DATAPROTECTION_LINK": "https://privacy.xing.com/en/privacy-policy",
    "WELCOME_REGISTRATION_EMAIL_LABEL": "E-mail",
    "WELCOME_REGISTRATION_FIRSTNAME_LABEL": "First name",
    "WELCOME_REGISTRATION_HEADLINE": "Get your free XING profile now:",
    "WELCOME_REGISTRATION_HEADLINE_REG_7088_B": "Sign up now – it's free and only takes 2 minutes!",
    "WELCOME_REGISTRATION_HEADLINE_REG_7088_C": "Sign up now – it's free and only takes 2 minutes!",
    "WELCOME_REGISTRATION_HEADLINE_REG_7088_D": "Sign up now – it's free and only takes 2 minutes!",
    "WELCOME_REGISTRATION_HEADLINE_REG_7088_E": "Sign up now – it's free and only takes 2 minutes!",
    "WELCOME_REGISTRATION_HEADLINE_REG_7396_B": "Registration is just a few clicks away:",
    "WELCOME_REGISTRATION_HEADLINE_REG_7396_C": "Boost your career:",
    "WELCOME_REGISTRATION_HEADLINE_REG_7396_D": "Get your free XING profile now:",
    "WELCOME_REGISTRATION_HEADLINE_REG_7550_CMP": "Register now and enjoy Premium free for 30 days:",
    "WELCOME_REGISTRATION_IGNORE_SOFT_EMAIL_ERRORS_LABEL": "Yes, {email} is the right e-mail address.",
    "WELCOME_REGISTRATION_LASTNAME_LABEL": "Last name",
    "WELCOME_REGISTRATION_PASSWORD_LABEL": "Password",
    "WELCOME_REGISTRATION_RECAPTCHA_HEADLINE": "Nearly done!",
    "WELCOME_REGISTRATION_RECAPTCHA_PARAGRAPH": "We need to make sure you're a real person to protect you and others from spam.",
    "WELCOME_REGISTRATION_SOFT_EMAIL_ERROR": "Please check your e-mail address again.",
    "WELCOME_REGISTRATION_SUBMIT_LABEL": "Continue",
    "WELCOME_REGISTRATION_TANDC_DESCRIPTION": "I accept XING's {tandc} and acknowledge the {dataprotection}.",
    "WELCOME_REGISTRATION_TANDC_LABEL": "GTC",
    "WELCOME_REGISTRATION_TIMER_CAPTCHA_BUTTON": "Press and hold me for 5 seconds",
    "WELCOME_REGISTRATION_TIMER_CAPTCHA_ERROR": "That didn't work. Please press and hold the button again for 5 seconds.",
    "WELCOME_REGISTRATION_TIMER_CAPTCHA_HEADLINE": "One last thing",
    "WELCOME_REGISTRATION_TIMER_CAPTCHA_PARAGRAPH": "To make sure you're a real person and not a bot, please press and hold the button below for 5 seconds.",
    "WELCOME_REGISTRATION_VIA_CV_BAD_FILE_ERROR_LABEL": "Your file is either too big or in an unsupported format. We recommend PDF files.",
    "WELCOME_REGISTRATION_VIA_CV_BUTTON_LABEL": "Upload CV",
    "WELCOME_REGISTRATION_VIA_CV_COMMON_ERROR_LABEL": "That didn't work. Please try again.",
    "WELCOME_REGISTRATION_VIA_CV_DESCRIPTION_DEFAULT": "Submit your CV now and we will provide you with personalized job recommendations.",
    "WELCOME_REGISTRATION_VIA_CV_DESCRIPTION_SUCCESS": "We've added your CV details to your XING profile. {br}{br} Please make sure everything is okay and add any information that's missing.",
    "WELCOME_REGISTRATION_VIA_CV_FILE_INFO_LABEL": "Supported formats: PDF, Word (DOCX) or Pages (max. 100 MB)",
    "WELCOME_REGISTRATION_VIA_CV_HEADLINE_DEFAULT": "Let recruiters come to you",
    "WELCOME_REGISTRATION_VIA_CV_HEADLINE_SUCCESS": "Your CV",
    "WELCOME_SEARCHPAGE_CTA": "Go ahead and find your next job, or connect with friends and colleagues:",
    "WELCOME_SEARCHPAGE_HEADLINE": "Look for jobs and people",
    "WELCOME_SEARCHPAGE_JOBS_BUTTON": "Jobs",
    "WELCOME_SEARCHPAGE_JOBS_PLACEHOLDER": "Enter a job title or keyword",
    "WELCOME_SEARCHPAGE_MEMBERS_BUTTON": "Members",
    "WELCOME_SEARCHPAGE_MEMBERS_PLACEHOLDER": "Name, company or keyword",
    "WELCOME_SEARCHPAGE_SEARCH_BUTTON": "Search",
    "WELCOME_SIGNUP_FORM_SUBMIT_REQUEST_ERROR": "Sorry, something  went wrong. Please try again.",
    "WELCOME_SIGNUP_FORM_VALIDATE_RELOAD_ERROR": "Something went wrong. Please enable cookies in your browser and reload the page.",
    "WELCOME_SIGNUP_FORM_VALIDATE_REQUEST_ERROR": "Sorry, something  went wrong. Please try again.",
    "WELCOME_SIGNUP_PAGE_META_TITLE": "Join now | XING",
    "WELCOME_SIGNUP_USPS_TITLE": "XING – The jobs network",
    "WELCOME_SIGNUP_USP_CONTACTS_COPY": "Get found by employers and over 20,000 recruiters.",
    "WELCOME_SIGNUP_USP_CONTACTS_TITLE": "Job offers just for you",
    "WELCOME_SIGNUP_USP_FREE_COPY": "Basic membership is free, but the opportunities are priceless.",
    "WELCOME_SIGNUP_USP_FREE_TITLE": "Free membership",
    "WELCOME_SIGNUP_USP_PRESENCE_COPY": "Find just the right job for you on XING.",
    "WELCOME_SIGNUP_USP_PRESENCE_TITLE": "Over 1 million jobs",
    "WELCOME_SIGNUP_USP_SECURITY_COPY": "Connect with new people and gather inspiration to boost your working life",
    "WELCOME_SIGNUP_USP_SECURITY_TITLE": "21 million members",
    "WELCOME_STARTPAGE_APP_STORE_BADGE_ALT": "Download on the App Store",
    "WELCOME_STARTPAGE_CULTURE_CHECK_BUTTON": "View culture assessment",
    "WELCOME_STARTPAGE_CULTURE_CHECK_IMG_ALT": "Graphic for the culture assessment",
    "WELCOME_STARTPAGE_CULTURE_CHECK_TEXT": "What do you prefer – flat hierarchies or clear instructions? There are many factors covering corporate culture, and you can find out which ones matter most to you. That way, you know what to look for in your next job. Check out the culture assessment now:",
    "WELCOME_STARTPAGE_CULTURE_CHECK_TITLE": "Find the right workplace for you",
    "WELCOME_STARTPAGE_CULTURE_COMPASS_BUTTON": "View Culture Compass",
    "WELCOME_STARTPAGE_CULTURE_COMPASS_IMG_ALT": "A graphic showing corporate culture with a scale ranging from traditional to modern.",
    "WELCOME_STARTPAGE_CULTURE_COMPASS_TEXT": "Ever wondered what the people are like at company XYZ? The culture compass shows you what it's like to work there. You'll discover a whole host of insights so you can make an informed decision before applying.",
    "WELCOME_STARTPAGE_CULTURE_COMPASS_TITLE": "Follow the Culture Compass",
    "WELCOME_STARTPAGE_DOWNLOAD_APP_CTA": "Ready for the new XING app?",
    "WELCOME_STARTPAGE_FORM_TITLE_REG_7371_01": "Sign up now – it's free and only takes 2 minutes:",
    "WELCOME_STARTPAGE_FORM_TITLE_REG_7371_02": "Sign up now – it's free and only takes 2 minutes:",
    "WELCOME_STARTPAGE_FORM_TITLE_REG_7371_03": "Sign up now – it's free and only takes 2 minutes:",
    "WELCOME_STARTPAGE_FORM_TITLE_REG_7371_04": "Sign up now – it's free and only takes 2 minutes:",
    "WELCOME_STARTPAGE_GOOGLE_PLAY_BADGE_ALT": "Get it on Google Play",
    "WELCOME_STARTPAGE_HEADER_BUTTON": "Join now",
    "WELCOME_STARTPAGE_HEADER_IMAGE_ALT": "Happy woman leaning back and stretching on an office chair",
    "WELCOME_STARTPAGE_HEADER_SUBTITLE": "Do your XING!",
    "WELCOME_STARTPAGE_HEADER_TITLE": "It’s time to be yourself.",
    "WELCOME_STARTPAGE_JOBSFINDER_CTA_BUTTON": "Register now",
    "WELCOME_STARTPAGE_JOBSFINDER_CTA_TEXT": "Find out how location and industry affect salaries. With XING.",
    "WELCOME_STARTPAGE_JOBSFINDER_IMG_ALT": "Woman in an uncomfortable position trying to work",
    "WELCOME_STARTPAGE_JOBSFINDER_SUBTITLE": "Be your true self:",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_CONSULTING": "Consulting",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_IT": "IT",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_LOGISTICS": "Logistics",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_MARKETING": "Marketing",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_MORE_JOBS": "More industries",
    "WELCOME_STARTPAGE_JOBSFINDER_SUGGESTION_PROJECT_MANAGEMENT": "Project management",
    "WELCOME_STARTPAGE_JOBSFINDER_TITLE": "Find the right job for you",
    "WELCOME_STARTPAGE_JOBS_DIRECTORY_NAVIGATION_TITLE": "Job titles that start with",
    "WELCOME_STARTPAGE_JOBS_VERSION_HEADER_BUTTON": "Register for free",
    "WELCOME_STARTPAGE_JOBS_VERSION_HEADER_SUBTITLE": "Or register now and we'll do the searching for you: ",
    "WELCOME_STARTPAGE_JOBS_VERSION_HEADER_TITLE": "Find the right job{br}\nfor you:",
    "WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_DOGS_FRIENDLY": "Dogs welcome",
    "WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_FLEXIBILITY": "Flexitime",
    "WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_PART_TIME": "Part-time",
    "WELCOME_STARTPAGE_JOBS_VERSION_JOB_FILTER_REMOTE": "Remote",
    "WELCOME_STARTPAGE_JOBS_VERSION_JOB_INPUT_PLACEHOLDER": "Enter a job title or keyword",
    "WELCOME_STARTPAGE_JOBS_VERSION_LOCATION_INPUT_PLACEHOLDER": "Location",
    "WELCOME_STARTPAGE_JOBS_VERSION_SEARCH_SUBMIT_BUTTON": "Search jobs",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_IMG_ALT": "Drawing of a person and a CV",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_TEXT": "Whether it's a change of city, lifestyle or just wanting something new – on XING it’s all about who you want to be in your working life. Let the real you shine through.",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_ONE_TITLE": "On XING, you're more than just your CV",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_IMG_ALT": "Drawing of a person holding a card showing various peoples' heads",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_TEXT": "Over 20,000 recruiters on XING are just a message away. Get in touch with HR, industry and labour market experts, and personally contact recruiters and employees at your dream company. Join us and do your XING!",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_THREE_TITLE": "Reach out to the right people",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_IMG_ALT": "Drawing of a person with various job-specific hats",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_TEXT": "Imagine a gallery of amazing jobs where you receive suggestions tailored to your preferences. Find out what you really want from your working life: how do you want to develop? Which employers do you like? What company culture do you want to be part of?",
    "WELCOME_STARTPAGE_JOBS_VERSION_SECTION_TWO_TITLE": "Over 1 million jobs and countless opportunities",
    "WELCOME_STARTPAGE_KEY_FEATURES_EVENTS": "1.7 million events",
    "WELCOME_STARTPAGE_KEY_FEATURES_JOBS": "Over 250,000 jobs",
    "WELCOME_STARTPAGE_KEY_FEATURES_MEMBERS": "21 million members",
    "WELCOME_STARTPAGE_KEY_FEATURES_NEWS": "Daily industry news",
    "WELCOME_STARTPAGE_MAIN_CTA_BUTTON": "Join now for free",
    "WELCOME_STARTPAGE_MAIN_CTA_TEXT": "XING – the leading online jobs network in German-speaking countries.",
    "WELCOME_STARTPAGE_MAIN_DESCRIPTION": "XING is your personal career companion. Connect with new people and discover exciting jobs as well as specialist news.",
    "WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_B": "Find a job to match your lifestyle.",
    "WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_C": "On XING you'll get to know interesting people and share ideas and inspiration.",
    "WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_D": "See what's new with your colleagues and discuss the things you're passionate about with like-minded people.",
    "WELCOME_STARTPAGE_MAIN_DESCRIPTION_REG_7088_E": "Build your very own network of contacts and share your ideas and inspiration with like-minded people.",
    "WELCOME_STARTPAGE_MAIN_TITLE": "XING – Ideas for a new world of work",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_B": "Find the right job for you",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_C": "Achieve more thanks to your network.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_D": "Careers are built on networks.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7088_E": "Meet the right people for your career.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_01": "Find the right job for the right work-life balance.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_02": "Say 'bye' to procrastination and 'hi' to the right job.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_03": "XING – Your professional companion.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7371_04": "Find the right job for the right work-life balance.",
    "WELCOME_STARTPAGE_MAIN_TITLE_REG_7747": "It’s time to be yourself.{br}\nDo your XING!",
    "WELCOME_STARTPAGE_MEMBER_DIRECTORY_NAVIGATION_TITLE": "Members whose last name starts with",
    "WELCOME_STARTPAGE_META_DESCRIPTION": "XING is your personal career companion. Connect with new people, discover exciting jobs, and gather valuable inspiration for your working life.",
    "WELCOME_STARTPAGE_META_TITLE": "XING – Ideas for a new world of work",
    "WELCOME_STARTPAGE_META_TITLE_JOB_SEARCH": "XING - Find the right job for you",
    "WELCOME_STARTPAGE_OG_SITENAME": "XING",
    "WELCOME_STARTPAGE_OG_TITLE": "XING – Ideas for a new world of work",
    "WELCOME_STARTPAGE_PROCEED_WITH_APPLE_BUTTON": "Continue with Apple",
    "WELCOME_STARTPAGE_PROCEED_WITH_GOOGLE_BUTTON": "Continue with Google",
    "WELCOME_STARTPAGE_XINGGUIDE_BUTTON": "Learn more",
    "WELCOME_STARTPAGE_XINGGUIDE_IMG_ALT": "A message from the XING Guide which recommends a job ad",
    "WELCOME_STARTPAGE_XINGGUIDE_TEXT": "Are you looking for a job or just started a new one? The XING Guide offers you advice on how to showcase yourself on XING. You'll also receive suggested contacts and articles worth reading.",
    "WELCOME_STARTPAGE_XINGGUIDE_TITLE": "Your professional companion",
    "WELCOME_TOGGLE_FORM_BUTTON_LOGIN": "Log in",
    "WELCOME_TOGGLE_FORM_BUTTON_REGISTER": "Register",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_0": "Your password is really easy to guess.",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_1": "Your password is really easy to guess.",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_2": "Your password is quite easy to guess.",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_3": "Add a few more characters just to be on the safe side.",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_4": "That's a decent password.",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_5": "This is a great password!",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_6": "Passwords don't get much better than this!",
    "ACCOUNT_FORMS_PASSWORD_STRENGTH_LESS_THAN": "Please make sure your password contains at least 8 characters.",
    "ERROR_REGISTER_EMAIL_REG": "We already know this e-mail address. Perhaps you already registered? If so, just log in now.",
    "ERROR_REGISTER_EMAIL_SECONDARY": "Please check the information below about this e-mail address.",
    "LOGIN_INVALID_PASSWORD": "Please check your login details and try again.",
    "LOGIN_NEW_REGISTER_TEXT": "Not joined XING yet? Register now free of charge",
    "LOGIN_OAUTH_INFO_PARAGRAPH": "Please sign in with your XING login details to grant {application} access to your XING data.",
    "WELCOME_APP_MINIREG_DISALLOWED_DOMAIN": "Your e-mail address seems to be incorrect. Maybe there's a typo?",
    "WELCOME_APP_MINIREG_ERROR_BLACKLIST_LICENSE_COMPANY_MATCH": "Would you like to showcase your company on XING? That's great: Go ahead and <a href=\"https://recruiting.xing.com/e-recruiting-loesungen/employerbranding?sc_o=welcome_signup_error_companies_suggestion\" target=\"_blank\">create a XING Page for your company</a>.",
    "WELCOME_APP_MINIREG_ERROR_BLACKLIST_LICENSE_MATCH": "Unfortunately you can't sign up with the details you've entered. If you have questions about this, feel free to <a href=\"https://www.xing.com/support/contact/registration/confirm_registration\">get in touch</a>.",
    "WELCOME_APP_MINIREG_ERROR_PASSWORD_FIRST_NAME": "For your own security you should choose a password different to your first name.",
    "WELCOME_APP_MINIREG_ERROR_PASSWORD_TOO_SHORT": "Please select a password (min. 8 characters).",
    "WELCOME_REGISTRATION_SECONDARY_EMAIL_ERROR": "This e-mail address is already in use with a different account. Do you really want to register as a new member?",
    "WELCOME_REGISTRATION_SKIP_SECONDARY_EMAIL_CHECK": "Yes, I'd like to register as a new member using the e-mail address {email}"
  }
}